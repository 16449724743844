<template>
  <div>
    <survey :survey="survey" />
  </div>
  <!-- <v-app class="background">
    <Topnav />
    <Sidenav />
  </v-app> -->
</template>

<script>
import * as SurveyVue from "survey-vue";
import "survey-vue/defaultV2.css";
import { SurveyPDF } from "survey-pdf";
// import Topnav from "@/components/navigators/Topnav";
// import Sidenav from "@/components/navigators/Sidenav";
SurveyVue.StylesManager.applyTheme("defaultV2");
import store from '@/store/index.js';

var Survey = SurveyVue.Survey;
const exportToPdfOptions = {
  fontSize: 12,
  haveCommercialLicense: true,
};

const savePdf = function (surveyData) {
  const surveyPdf = new SurveyPDF(
    store.getters.getSurveyContent,
    exportToPdfOptions
  );
  surveyPdf.data = surveyData;
  surveyPdf.save();
};


export default {
  name: "SurveyExporter",
  components: {
    Survey,
    // Topnav,
    // Sidenav,
  },

  async mounted() {
    this.fetchSurvey()
  },

  async beforeDestroy() {
    await localStorage.removeItem("surveyContentForPdf");
  },

  data() {
    return {
      survey: [],
    };
  },
  methods: {
    async fetchSurveyPayload(url) {
      let response = await fetch(url);
      let data = response.text();
      return data;
    },

    async fetchSurvey(){
      await this.$store.dispatch(
        "loadSurveyPayload",
        this.$route.params.surveyId
        );
      const filesRoot = process.env.VUE_APP_FILES_ROOT;

      let surveyContent = await this.fetchSurveyPayload(
        filesRoot + store.getters.getSurveyPayload
      );

      this.$store.commit("setSurveyContent", surveyContent)
      var model = new SurveyVue.Model(surveyContent);

      model.addNavigationItem({
        id: "pdf-export",
        title: "Save as PDF",
        action: ()=> savePdf(model.data),
      });
      this.$nextTick(() => {
        this.survey = model
      })

    }
  },
};
</script>